import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Suggest from '../../../components/Suggest';

import { LABELFIELDSRU } from '../../../bi/constants/trips';
import styles from '../styles/form.module.scss';

const NODEPARTMENT = 'У сотрудника нет отдела';
const SELECTEMPLOYEE = 'Сначала выберите сотрудника';

export default class SelectDepartment extends Component {
  static propTypes = {
    departments: PropTypes.array.isRequired,
    validationDepartment: PropTypes.string,
    onSelect: PropTypes.func.isRequired,
    currentDepartmentId: PropTypes.number.isRequired,
    employeesList: PropTypes.array,
    currentEmployeeId: PropTypes.number,
    isHotel: PropTypes.bool,
  };

  static defaultProps = {
    validationDepartment: '',
    currentEmployeeId: null,
    employeesList: [],
    isHotel: false,
  };

  foundDepartmentName = (currentDepartmentId, departments) => {
    if (currentDepartmentId) {
      const department = departments.find(({ id }) => id === currentDepartmentId);

      if (department) {
        return department.name;
      }
    }

    return '';
  }

  render() {
    const { departments, onSelect, currentDepartmentId, validationDepartment, currentEmployeeId, employeesList, isHotel } = this.props;
    const warningName = currentEmployeeId && (!isHotel || Object.keys(employeesList[0]).length !== 0) ? NODEPARTMENT : SELECTEMPLOYEE;

    if (departments.length > 0) {
      return (
        <div className={ `${styles['col-1-3']} ${styles.department}` }>
          <Suggest
            title={ LABELFIELDSRU.DEPARTMENT }
            valid={ validationDepartment }
            suggests={ departments }
            onSelect={ onSelect }
            currentLabel={ this.foundDepartmentName(currentDepartmentId, departments) }
          />
        </div>
      );
    }
    return (
      <div className={ styles['col-1-3'] }>
        <div className={ styles.select }>
          <div className={ styles.wrap }>
            <label>{ LABELFIELDSRU.DEPARTMENT }</label>
            <div className={ styles['projects-absence'] }><span>{ warningName }</span></div>
          </div>
        </div>
      </div>
    );
  }
}

import React from 'react';
import PropTypes from 'prop-types';

import Suggest from '../../../components/Suggest';

import styles from '../styles/form.module.scss';

const SelectAnalytics = ({
  validation = '',
  analytics,
  valueId = null,
  onSelect = () => {},
}) => {
  const suggests = analytics.Values.map(({ Name, Id }) => ({ id: Id, name: Name }));
  const value = suggests.find(({ id }) => id === valueId) || { name: '' };

  return (
    <div className={ styles['col-1-3'] }>
      <Suggest
        title={ analytics.Name }
        valid={ validation }
        suggests={ suggests }
        currentLabel={ value.name }
        onSelect={ onSelect }
      />
    </div>
  );
};

SelectAnalytics.propTypes = {
  valueId: PropTypes.number,
  onSelect: PropTypes.func.isRequired,
  validation: PropTypes.string,
  analytics: PropTypes.object.isRequired,
};

SelectAnalytics.defaultProps = {
  validation: '',
  valueId: null,
};

export default SelectAnalytics;

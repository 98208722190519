import React from 'react';
import PropTypes from 'prop-types';

import { dateUtcFormat } from '../../../../../../bi/utils/formatDate';
import MoneyFormat from '../../../../../../bi/utils/money';

import { TAXI_CLASSES_TITLE } from '../../../../../../constants/taxi';
import { MONTHPATTERN } from '../../../../../../constants/time';

import styles from '../../../../styles/trip.module.scss';

const LABELS = {
  FROM: 'c',
  TO: 'по',
  SPENT: 'потрачено',
  OUT: 'из',
  TAXI_CLASS: taxiClass => `Класс такси: ${taxiClass}`,
};

const InfoItem = ({
  voucherId,
  voucher,
  reserveData: {
    Traveller: { Name, Patronymic, Surname },
  },
  company,
}) => {
  if (!voucher) {
    return null;
  }

  const { StartDate, EndDate, Classes, CurrentAmount, BeginAmount } = voucher;

  const renderDate = () => `${LABELS.FROM} ${dateUtcFormat(StartDate, MONTHPATTERN)} ${LABELS.TO} ${dateUtcFormat(EndDate, MONTHPATTERN)}`;

  const renderClasses = () => LABELS.TAXI_CLASS(Classes.map(name => TAXI_CLASSES_TITLE[name].toLowerCase()).join(', '));

  const renderVoucherInfo = () => {
    const employeeName = `${Surname} ${Name.charAt(0)}.${Patronymic ? `${Patronymic.charAt(0)}.` : ''}`;

    const spentAmount = BeginAmount - CurrentAmount;
    const currentAmountHtml = `${LABELS.SPENT} ${MoneyFormat.symbolWithMoneyWithDecimal(Math.abs(spentAmount))}`;
    const currentBegintHtml = `${LABELS.OUT} ${MoneyFormat.symbolWithMoneyWithDecimal(BeginAmount)}`;

    return (
      <div className={ `${styles['col-5-7']} ${styles['order-info']}` }>
        <div className={ styles.row }>
          <div className={ styles['col-1-3'] }>
            { voucherId }
          </div>
          <div className={ styles['col-1-3'] } >
            {renderDate()}
          </div>
          <div className={ `${styles['col-1-4']} ${styles.text_right}` } >
            <div>
              {currentAmountHtml}
            </div>
            <div>
              {currentBegintHtml}
            </div>
          </div>
        </div>
        <div className={ styles.row }>
          <div className={ `${styles['col-1-3']} ${styles['col-info']}` }>
            <div className={ styles.col }>{employeeName}</div>
            <div className={ `${styles.col} ${styles['company-name']}` }>{ company }</div>
          </div>
          <div className={ styles['col-1-3'] }>
            <div>
              { renderClasses() }
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    renderVoucherInfo()
  );
};

InfoItem.propTypes = {
  voucherId: PropTypes.string.isRequired,
  voucher: PropTypes.object.isRequired,
  reserveData: PropTypes.object.isRequired,
  company: PropTypes.string.isRequired,
};

export { InfoItem };

import React from 'react';
import PropTypes from 'prop-types';

import Suggest from '../../../components/Suggest';
import { LABELFIELDSRU } from '../../../bi/constants/trips';
import styles from '../styles/form.module.scss';

const title = LABELFIELDSRU.ORGANIZATION;

const foundCompanyName = (currentCompanyId, companies) => (
  currentCompanyId ? companies.find(({ id }) => id === currentCompanyId).name : ''
);

const SelectCompany = ({
  companies,
  currentCompanyId,
  validationCompany,
  onSelect,
}) => companies.length > 0 && (
  <div className={ styles['col-1-3'] }>
    <div className={ styles.wrap }>
      <Suggest
        title={ title }
        valid={ validationCompany }
        suggests={ companies }
        currentLabel={ foundCompanyName(currentCompanyId, companies) }
        onSelect={ onSelect }
      />
    </div>
  </div>
);


SelectCompany.propTypes = {
  currentCompanyId: PropTypes.number.isRequired,
  validationCompany: PropTypes.string.isRequired,
  companies: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default SelectCompany;
